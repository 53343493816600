import React from 'react'
import HeroImage from '../../Elements/HeroSection/HeroImage'
import IconCard from '../../Elements/IconCard/IconCard'
import ServiceContent from '../../Elements/Service/ServiceContent'
import Service from '../../Elements/Service/Service'
import HeroSection from '../../Elements/HeroSection/HeroSection'
import Blog from '../../Elements/Blog/Blog'
import CallSection from '../../Elements/Call/CallSection'

const Home = () => {
  return (
    <>
    <HeroImage/>
    <HeroSection/>
    <ServiceContent/>
    <Service/>
    <Blog/>
    <IconCard/>
    <CallSection/>
     
    </>
  )
}

export default Home
