import React from 'react';
import './HeroImage.css'; // Import CSS for styling
import image from '../../images/apollo.jpg';

function HeroImage() {
    return (
        <section className="hero-section">
            <div className="hero-image">
                <img src={image} alt="Hero" />
                <div className="hero-text">
                   {/* <h1>Welcome to Apollo Hospital</h1>
                    <p>Providing quality healthcare services since 1983</p> */}
                </div>
            </div>
        </section>
    );
}

export default HeroImage;
