import React from 'react';
import './CallSection.css'; // Import CSS file for styling

const CallSection = () => {
  return (
    <div className="sticky-call-icon">
      <a href="tel:9869182611">
        <i className="fas fa-phone"></i>
      </a>
    </div>
  );
}

export default CallSection;
