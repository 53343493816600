import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Navbar from './Components/Elements/Navbar/Navbar';
import Footer from './Components/Elements/Footer/Footer';
import Service from './Components/Elements/Service/Service';
import HeroSection from './Components/Elements/HeroSection/HeroSection';
import HeroImage from './Components/Elements/HeroSection/HeroImage';
import ContactImage from './Components/Pages/Contact/ContactImage';
import Home from './Components/Pages/Home/Home';
import Contact from './Components/Pages/Contact/Contact';
import Doctors from './Components/Pages/Doctors/Doctors';
import Appointment from './Components/Elements/Appointment/Appointment';


const App = () => {
  return (
   <>
   <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home/>}  />
        <Route path="/hospitals" />
        <Route path="/specialities" />
        <Route path="/procedures" />
        <Route path="/doctors" element={<Doctors/>}/>
        <Route path="/contact" element={<Contact/>} />
        <Route path="/appointment" element={<Appointment/>}/>
      </Routes>
      <Footer/>
    </Router>
   </>
  );
};

export default App;
