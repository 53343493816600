import React, { useEffect } from 'react';
import logo from '../../images/logo.svg'; // Replace with the actual path to your logo image
import './Footer.css'

function Footer() {
 

  return (
    <footer className="footer parallax-footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>About Us</h3>
          <img src={logo} height={90} alt="Logo" />
          <p>"Touch A Billion Lives"</p>
        </div>

        <div className="footer-column">
          <h3>Contact</h3>
          <p>Email: aicnepal10@gmail.com</p>
          <p>Contact Number: 9869182611</p>
        </div>

        <div className="footer-column">
          <h3>Specialities</h3>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/orthopedic/">Orthopaedics</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/nephrology-urology/">Nephrology & Urology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/bariatric-surgery/">Bariatric Surgery</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/heart/">Cardiology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/pulmonology/">Pulmonology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/gastroenterology/">Gastroenterology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/spine/">Spine Surgery</a>
          <a className='footer-a' href="https://www.apollohospitals.com/cancer-treatment-centres/">Oncology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/neurology-neurosurgery/">Neurology & Neurosurgery</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/transplantation/">Organ Transplantation</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/robotics-surgery/">Robotic Surgery</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/preventivehealth/">Preventive Health Check Up</a>
          <a className='footer-a' href="https://www.askapollo.com/book-health-check/apollo-pro-health">Apollo ProHealth</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/emergency-trauma-care-services/">Emergency Care</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/ent/">Ear,Nose And Throat</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/vascular-surgery/">Vascular Surgery</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/pediatrics/">Pediatrics</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/dermatology/">Dermatology</a>
          <a className='footer-a' href="https://www.apollohospitals.com/departments/obstetrics-and-gynaecology/">Obstetrics And Gynaecology</a>
        </div>

        
        <div className="footer-column">
          <h3>Hospitals</h3>
          <a className='footer-a' href="https://www.apollohospitals.com/locations/india/">Hospitals In India</a>
          <a className='footer-a' href="https://www.apollohospitals.com/?id=203&Itemid=220">International Hospitals</a>
          <a className='footer-a' href="https://www.apolloclinic.com">Apollo Canics</a>
          <a className='footer-a' href="https://www.apollohospitals.com/?id=206&Itemid=222">Reach Hospitals</a>
          <a className='footer-a' href="https://www.apollocradle.com">Apollo Cradle</a>
          <a className='footer-a' href="https://www.apollofertility.com/?utm_source=website&utm_medium=ahel-footer&utm_campaign=internal-links">Apollo Fertiaty</a>
          <a className='footer-a' href="https://www.apollohospitals.com/cancer-treatment-centres/">Apollo Cancer Centers</a>
          <a className='footer-a' href="https://www.apollohospitals.com/proton-therapy/">Apollo Proton Centers</a>
         
        </div>

        <div className="footer-column">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/medicalhintsnepal"><i className="fab fa-facebook"></i></a>
            <a href="https://www.tiktok.com/@medicalhintsnepal"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-separator"></div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Apollo Hospital. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
