import React from 'react'
import ContactImage from './ContactImage'
import ContactForm from './ContactForm'
import ServiceContent from '../../Elements/Service/ServiceContent'
import ContactSection from './ContactSection'
import CallSection from '../../Elements/Call/CallSection'

const Contact = () => {
  return (
    <>
    <ContactImage/>
    <ContactForm/>
    <ServiceContent/> 
    <CallSection/>
    </>
  )
}

export default Contact
