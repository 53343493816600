import React from 'react';
import './TopDesc.css'

const TopDesc = () => {
    return (
        <div className="contact-info">
            <div className="phone">
          {/*     <i className="fas fa-phone"></i> {/* Assuming you're using Font Awesome for icons */}
                <span></span>
            </div>
            <div className="phone">
              <i className="fa-brands fa-whatsapp"></i> {/* Assuming you're using Font Awesome for icons */}
                <span>9869182611</span>
            </div>
         <div className="email">
                <i className="fas fa-envelope"></i>
                <a href="https://mail.google.com/mail/u/0/#inbox">aicnepal10@gmail.com</a>
    </div> 
            <div className="social-icons-1">
                <a href="https://www.facebook.com/abchealthcaresolution/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </a>
                
                <a href="#" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                </a>
            </div>
        </div>
    );
}

export default TopDesc;
