import React, { useState } from 'react';
import './DoctorCard.css';

const DoctorCard = () => {
  const doctorsData = [
    { name: "Dr.Sanjeev Aneja", specialization: "Anaesthesia" },
    { name: "Dr.Chitra Chatterji", specialization: "Anaesthesia" },
    { name: "Dr.Sunil Chaturvedi", specialization: "Anaesthesia" },
    { name: "Dr.Subash Chawla", specialization: "Anaesthesia" },
    { name: "Dr.K.J Choudhary", specialization: "Anaesthesia" },
    { name: "Dr.Purnima Dhar", specialization: "Anaesthesia" },
    { name: "Dr.Jai S George", specialization: "Anaesthesia" },
    { name: "Dr.Vikas Gupta", specialization: "Anaesthesia" },
    { name: "Dr.Nidhi Gupta", specialization: "Anaesthesia" },
    { name: "Dr.Pratyush Gupta", specialization: "Anaesthesia" },
    { name: "Dr.Bharminder Kaur", specialization: "Anaesthesia" },
    { name: "Dr.Meera Kharbanda", specialization: "Anaesthesia" },
    { name: "Dr.Vijay Kumar", specialization: "Anaesthesia" },
    { name: "Dr.K Lalitha", specialization: "Anaesthesia" },
    { name: "Dr.Abha Gupta", specialization: "Bio Chemistry" },
    { name: "Dr.Sharmila Verma", specialization: "Bio Chemistry" },
    { name: "Dr.Jyothi Shanker Raychaudhari", specialization: "Bone Marrow Transplant" },
    { name: "Dr.Shishir Seth", specialization: "Bone Marrow Transplant" },
    { name: "Dr.Partha Prateem Choudhary", specialization: "Cardiology" },
    { name: "Dr.Mahesh Chandra Garg", specialization: "Cardiology" },
    { name: "Dr.S.K Gupta", specialization: "Cardiology" },
    { name: "Dr.Vivek Gupta", specialization: "Cardiology" },
    { name: "Dr.P.K Ghosh", specialization: "Cardiology" },
    { name: "Dr.V.Hariharan", specialization: "Cardiology" },
    { name: "Dr.Anup Dhir", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.Shahin Nooreyezdan", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.R.K Seth", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.Kuldeep Singh", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.I.P Singh", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.Rajesh Watts", specialization: "Cosmetic & Plastic Surgery" },
    { name: "Dr.Shivani Agarwal", specialization: "Dental" },
    { name: "Dr.Vikrant Choudhary", specialization: "Dental" },
    { name: "Dr.Ashish Kakkar", specialization: "Dental" },
    { name: "Dr.Sanju Lall", specialization: "Dental" },
    { name: "Dr.Neeraj Singh", specialization: "Dental" },
    { name: "Dr.Neeraj Verma", specialization: "Dental" },
    { name: "Dr.Bhaba Nanda Das", specialization: "Cardio Thoracic Vascular Surgery" },
    { name: "Dr.Anoop K Ganjoo", specialization: "Cardio Thoracic Vascular Surgery" },
    { name: "Dr.Mukesh Goel", specialization: "Cardio Thoracic Vascular Surgery" },
    { name: "Dr.Sangita Rawat", specialization: "Clinical Pathology" },
    { name: "Dr.Rashmi Thakur", specialization: "Clinical Pathology" },
    { name: "Dr.S.K Bose ", specialization: "Dermatology" },
    { name: "Dr.Ramji Gupta", specialization: "Dermatology" },
    { name: "Dr.Ravi Kumar Joshi", specialization: "Dermatology" },
    { name: "Dr.D.M Mahajan", specialization: "Dermatology" },
    { name: "Dr.Anju Mangla", specialization: "Dermatology" },
    { name: "Dr.M.A Siddiqui", specialization: "Dermatology" },
    { name: "Dr.Priya Darshini Pal Singh", specialization: "Emergency" },
    { name: "Dr.C.M Batra", specialization: "Endocrinology" },
    { name: "Dr.Richa Chaturvedi", specialization: "Endocrinology" },
    { name: "Dr.Asim Siddiqui", specialization: "Endocrinology" },
    { name: "Dr.R.P Singh", specialization: "Endocrinology" },
    { name: "Dr.S.K Wangnoo", specialization: "Endocrinology" },
    { name: "Dr.Chanchal", specialization: "Fetal Medicine" },
    { name: "Dr.Anita Kaul", specialization: "Fetal Medicine" },
    { name: "Dr.Seema Thaker", specialization: "Fetal Medicine" },
    { name: "Dr.Atul Ahuja", specialization: "ENT" },
    { name: "Dr.S.H Ansari", specialization: "ENT" },
    { name: "Dr.P.L Dhingra", specialization: "ENT" },
    { name: "Dr.Ameet Kishore", specialization: "ENT" },
    { name: "Dr.Kalpana Nagpal", specialization: "ENT" },
    { name: "Dr.Suresh Singh Naruka", specialization: "ENT" },
    { name: "Dr.Tarun Prakash", specialization: "ENT" },
    { name: "Dr.Rajeev Puri", specialization: "ENT" },  
    { name: "Dr.Girish Raheja", specialization: "ENT" },
    { name: "Dr.Sandeep Sindhu", specialization: "ENT" },
    { name: "Dr.Arvind Soni", specialization: "ENT" },
    { name: "Dr.Anil C Anand", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.D.K Bhargava", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Sohan Lal Broor", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Amitabha Dutta", specialization: "Gastroentrology & Hepatology" }, 
    { name: "Dr.Hitendra K Garg", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Vishal Garg", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Sundeep Khanna", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Sanjay Sikka", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.N.M Tikkoo", specialization: "Gastroentrology & Hepatology" },
    { name: "Dr.Deepak Govil", specialization: "Surgical Gastroenterology" },
    { name: "Dr.Vivek Tandon", specialization: "Surgical Gastroenterology" },
    { name: "Dr.H.P Garg", specialization: "General Surgery" },
    { name: "Dr.Deepshika Arora", specialization: "Histopathology" },
    { name: "Dr.Lata G Jadhav", specialization: "Histopathology" },
    { name: "Dr.Tarun Sahini", specialization: "Hyperbaric Oxygen Therapy" },
    { name: "Dr.S.K Agarwal", specialization: "Internal Medicine" },
    { name: "Dr.Alok Agarwal", specialization: "Internal Medicine" },
    { name: "Dr.Kamal Ahmad", specialization: "Internal Medicine" },
    { name: "Dr.V.K Aneja", specialization: "Internal Medicine" },
    { name: "Dr.S.Chatterjee", specialization: "Internal Medicine" },
    { name: "Dr.M.S Chaudhary", specialization: "Internal Medicine" },
    { name: "Dr.J M Dua", specialization: "Internal Medicine" },
    { name: "Dr.Sunil Kumar", specialization: "Medical Physicist" },
    { name: "Dr.Saji Oommen", specialization: "Medical Physicist" },
    { name: "Dr.Sanjay Kumar Rout", specialization: "Medical Physicist" },
    { name: "Dr.Hena Butta", specialization: "Microbiology" },
    { name: "Dr.Reetika Dawar", specialization: "Microbiology" },
    { name: "Dr.Leena Mediratta", specialization: "Microbiology" },
    { name: "Dr.Raman Sardana", specialization: "Microbiology" },
    { name: "Dr.D. Agarwal", specialization: "Nephrology" },
    { name: "Dr.Jayant Kumar Hota", specialization: "Nephrology" },
    { name: "Dr.Sanjiv Jasuja", specialization: "Nephrology" },
    { name: "Dr.Akhil Mishra", specialization: "Nephrology" },
    { name: "Dr.Gaurav Sagar", specialization: "Nephrology" },
    { name: "Dr.Ashok Kumar Sarin", specialization: "Nephrology" },
    { name: "Dr.Kailash N Singh", specialization: "Nephrology" },
     { name: "Dr.Rakesh Agarwal", specialization: "Neurology" },
    { name: "Dr.G.K Ahuja", specialization: "Neurology" },
    { name: "Dr.Charu Gauba", specialization: "Neurology" },
    { name: "Dr.Anoop Kohli", specialization: "Neurology" },
    { name: "Dr.P.N Renjen", specialization: "Neurology" },
    { name: "Dr.Vinit Suri", specialization: "Neurology" },
    { name: "Dr.Mukul Verma", specialization: "Neurology" },
    { name: "Dr.A.K Banerji", specialization: "Neuro Surgery" },
    { name: "Dr.Ravi Bhatia", specialization: "Neuro Surgery" },
    { name: "Dr.Pranav Kumar", specialization: "Neuro Surgery" },
    { name: "Dr.C.M Malhotra", specialization: "Neuro Surgery" },
    { name: "Dr.Sunit Mediratta", specialization: "Neuro Surgery" },
    { name: "Dr.Rajendra Prasad", specialization: "Neuro Surgery" },
    { name: "Dr.Varindra Paul Singh", specialization: "Neuro Surgery" },
    { name: "Dr.S.K Sogani", specialization: "Neuro Surgery" },
    { name: "Dr.Sudhir Tyagi", specialization: "Neuro Surgery" },
    { name: "Dr.Bhanu Pant", specialization: "Neuro Physiology" },
    { name: "Dr.Hans Raj", specialization: "Neuro Physiology" },
    { name: "Dr.Saroja Balan", specialization: "Neonatology" },
    { name: "Dr.Uma Ravishankar", specialization: "Nuclear Medicine" },
    { name: "Dr.Lalita Badhwar", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Puneet Bedi", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Geeta Chadha", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.P Changulani", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Asawari Kesari Kapoor", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Shakti Bhan Khanna", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Harmeet Malhotra", specialization: "Obstetrics & Gynaecology" },
    { name: "Dr.Shefali Agrawal", specialization: "Oncology Surgical" },
    { name: "Dr.Praveen K Garg", specialization: "Oncology Surgical" },
    { name: "Dr.Sameer Kaul", specialization: "Oncology Surgical" },
    { name: "Dr.Raquaya Ahmad Mir", specialization: "Oncology Surgical" },
    { name: "Dr.Feroz Pasha", specialization: "Oncology Surgical" },
    { name: "Dr.Rajinder Kaur Saggu", specialization: "Oncology Surgical" },
    { name: "Dr.Sidharth Sahni", specialization: "Oncology Surgical" },
    { name: "Dr.PK Das", specialization: "Oncology Medical" },
    { name: "Dr.Harsh Dua", specialization: "Oncology Medical" },
    { name: "Dr.Dipanjan Panda", specialization: "Oncology Medical" },
    { name: "Dr.Manish Singhal", specialization: "Oncology Medical" },
    { name: "Dr.Mano Badhuria", specialization: "Oncology Radiation" },
    { name: "Dr.GK Jadhav", specialization: "Oncology Radiation" },
    { name: "Dr.Robin Khosa", specialization: "Oncology Radiation" },
    { name: "Dr.Sapana Manocha", specialization: "Oncology Radiation" },
    { name: "Dr.Sapna Nangia", specialization: "Oncology Radiation" },
    { name: "Dr.Ashu Agarwal", specialization: "Ophthalmology" },
    { name: "Dr.Harsh Kumar", specialization: "Ophthalmology" },
    { name: "Dr.Uma Malliah", specialization: "Ophthalmology" },
    { name: "Dr.Aniel Malhotra", specialization: "Ophthalmology" },
    { name: "Dr.Ranjana Mithal", specialization: "Ophthalmology" },
    { name: "Dr.LR Seth", specialization: "Ophthalmology" },
    { name: "Dr.RP Singh", specialization: "Ophthalmology" }, 
    { name: "Dr.Dinesh Talwar", specialization: "Ophthalmology" },
    { name: "Dr.Lalit Verma", specialization: "Ophthalmology" },
    { name: "Dr.Amit Kumar Agrawal", specialization: "Orthopaedic" },
    { name: "Dr.Kulbhushan Attri", specialization: "Orthopaedic" },
    { name: "Dr.Vibhu Bahl", specialization: "Orthopaedic" }, 
    { name: "Dr.Anoop Bandil", specialization: "Orthopaedic" },
    { name: "Dr.Harsh Bhargawa", specialization: "Orthopaedic" },
    { name: "Dr.PN Dubey", specialization: "Pediatric" }, 
    { name: "Dr.Deepa Sharma", specialization: "Pediatric" },
    { name: "Dr.Prita Trehan", specialization: "Pediatric" },
    { name: "Dr.Sujit Chowdhary", specialization: "Pediatric Urology & Pediatric Surgery" }, 
    { name: "Dr.BD Dwivedi", specialization: "Pediatric Urology & Pediatric Surgery" },
    { name: "Dr.Shanti Talwar", specialization: "Pediatric Urology & Pediatric Surgery" },
    { name: "Dr.Krishna Yadav", specialization: "Pediatric Urology & Pediatric Surgery" },
    { name: "Dr.Muthu Jothi", specialization: "Pediatric Cadiothoracic Surgery" },
    { name: "Dr.Manisha Chakrabarti", specialization: "Pediatric Cardiology" },
    { name: "Dr.Vineet Bhushan Gupta", specialization: "Pediatric Neurology" },
    { name: "Dr.Veena Kalra", specialization: "Pediatric Neurology" },
    { name: "Dr.Shina Menon", specialization: "Pediatric Nephrology" },
    { name: "Dr.R N Srivastava", specialization: "Pediatric Nephrology" },
    { name: "Dr.Ramani Narasimhan", specialization: "Pediatric Orthopaedics" },
    { name: "Dr.Anupam Sibal", specialization: "Pediatric Gastroenterology & Hepatology" },
    { name: "Dr.Anita Bakshi", specialization: "Pediatric Intensive Care" },
    { name: "Dr.Nameet Jerath", specialization: "Pediatric Intensive Care" },
    { name: "Dr.Manas Kalra", specialization: "Pediatric Oncology" },
    { name: "Dr.Amita Mahajan", specialization: "Pediatric Oncology" },
    { name: "Dr.IPS Kochar", specialization: "Pediatric Endocrinology" },
    { name: "Dr.Reetesh Gupta", specialization: "Pediatric Cardiac Intensivist" },
    { name: "Dr.Rakhi Anand", specialization: "Psychology" },
    { name: "Dr.Deepali Kapoor", specialization: "Psychology" },
    { name: "Dr.Ekta Soni", specialization: "Psychology" },
    { name: "Dr.Achal Bhagat", specialization: "Psychiatry" },
    { name: "Dr.Monica Chib", specialization: "Psychiatry" },
    { name: "Dr.Naetu Narang", specialization: "Psychiatry" },
    { name: "Dr.Sandeep Vohra", specialization: "Psychiatry" },
    { name: "Dr.Anoop S Arora", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Anshu Choudhary", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Veena Chowdhary", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Prakash Amul Desai", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Nitin P Ghonge", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Balraj Singh Gill", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Usha Kaul", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Amita Mitra", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Harsh Rastogi", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Ruchi Rastogi", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Vijay Singh Rawat", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Reeti Sahni", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Sandeep Vohra", specialization: "Radiology/Radiodiagnosis" },
    { name: "Dr.Avdhesh Bansal", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.Rajesh K Chawla", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.Sudha Kansal", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.MS Kanwar", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.Nikhil Modi", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.Deepak Rosha", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.Sanjay Sobti", specialization: "Respiratory, Critical Care & Sleep Medicine" },
    { name: "Dr.SJ Gupta", specialization: "Rheumatology" },
    { name: "Dr.Rohini Handa", specialization: "Rheumatology" },
    { name: "Dr.Sandeep Kumar Upadhyaya", specialization: "Rheumatology" },
    { name: "Dr.Shaleen Agrawal", specialization: "Transplant Surgery" },
    { name: "Dr.Sandeep Guleria", specialization: "Transplant Surgery" },
    { name: "Dr.Subash Gupta", specialization: "Transplant Surgery" },
    { name: "Dr.SN Mehta", specialization: "Transplant Surgery" },
    { name: "Dr.Vijaya Rajakumari", specialization: "Transplant Surgery" },
    { name: "Dr.Raja Sekhar", specialization: "Transplant Surgery" },
    { name: "Dr.Vijaya Rajakumari", specialization: "Transplant and Surgical Gastroenterology" },
    { name: "Dr.Raja Sekhar", specialization: "Transplant and Surgical Gastroenterology" },
    { name: "Dr.Mohit Chowdhary", specialization: "Transfusion Medicine" },
    { name: "Dr.RN Makroo", specialization: "Transfusion Medicine" },
    { name: "Dr.Urmil Bala Kanwar", specialization: "Ultrasonography" },
    { name: "Dr.Anshuman Agrawal", specialization: "Urology & Andrology" },
    { name: "Dr.Vipin Arora", specialization: "Urology & Andrology" },
    { name: "Dr.Suresh Kumar Rawat", specialization: "Urology & Andrology" },
    { name: "Dr.Ajit Saxena", specialization: "Urology & Andrology" },
    { name: "Dr.N Subramanian", specialization: "Urology & Andrology" },
    { name: "Dr.Rajesh Taneja", specialization: "Urology & Andrology" },
    { name: "Dr.Nitish Anchal", specialization: "Vascular & Endovascular Surgery" },
    { name: "Dr.Jaisom Chopra", specialization: "Vascular & Endovascular Surgery" },
    { name: "Dr.Rakesh Mahajan", specialization: "Vascular & Endovascular Surgery" },

    // Add other doctor data here
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [filterOption, setFilterOption] = useState('All');

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const filteredDoctors = doctorsData.filter(doctor => {
    // Filter by search query and filter option
    const searchMatch = doctor.name.toLowerCase().includes(searchQuery.toLowerCase());
    const filterMatch = filterOption === 'All' || doctor.specialization === filterOption;
    return searchMatch && filterMatch;
  });

  return (
    <section className="doctor-section">
      <div className="search-filter">
        <input
          type="text"
          placeholder="Search doctor..."
          value={searchQuery}
          onChange={handleSearch}
        />
        <select value={filterOption} onChange={handleFilterChange}>
          <option value="All">All Specializations</option>
          {/* Add other filter options based on your data */}
          <option value="Anaesthesia">Anaesthesia</option>
          <option value="Bio Chemistry">Bio Chemistry</option>
          <option value="Cardiology">Cardiology</option>
          <option value="Cosmetic & Plastic Surgery">Cosmetic & Plastic Surgery</option>
          <option value="Clinical Pathology">Clinical Pathology</option>
          <option value="Cardio Thoracic Vascular Surgery">Cardio Thoracic Vascular Surgery</option>
          <option value="Dental">Dental</option>
          <option value="Dermatology">Dermatology</option>
          <option value="Emergency">Emergency</option>
          <option value="Endocrinology">Endocrinology</option>
          <option value="ENT">ENT</option>
          <option value="Fetal Medicine">Fetal Medicine</option>
          <option value="Gastroentrology & Hepatology">Gastroentrology & Hepatology</option>
          <option value="Surgical Gastroenterology">Surgical Gastroenterology</option>
          <option value="General Surgery">General Surgery</option>
          <option value="Histopathology">Histopathology</option>
          <option value="Hyperbaric Oxygen Therapy">Hyperbaric Oxygen Therapy</option> 
          <option value="Internal Medicine">Internal Medicine</option>
          <option value="Medical Physicist">Medical Physicist</option>
          <option value="Microbiology">Microbiology</option>
          <option value="Nephrology">Nephrology</option>
          <option value="Neurology">Neurology</option>
          <option value="Neuro Surgery">Neuro Surgery</option>
          <option value="Neuro Physiology">Neuro Physiology</option>
          <option value="Neonatology">Neonatology</option>
          <option value="Nuclear Medicine">Nuclear Medicine</option>
          <option value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</option>
          <option value="Oncology Surgical">Oncology Surgical</option>
          <option value="Oncology Medical">Oncology Medical</option>
          <option value="Oncology Radiation">Oncology Radiation</option>
          <option value="Ophthalmology">Ophthalmology</option>
          <option value="Orthopaedic">Orthopaedic</option>
          <option value="Pediatric">Pediatric</option>
          <option value="Pediatric Urology & Pediatric Surgery">Pediatric Urology & Pediatric Surgery</option>
          <option value="Pediatric Cadiothoracic Surgery">Pediatric Cadiothoracic Surgery</option>
          <option value="Pediatric Cardiology">Pediatric Cardiology</option>
          <option value="Pediatric Neurology">Pediatric Neurology</option>
          <option value="Pediatric Nephrology">Pediatric Nephrology</option>
          <option value="Pediatric Orthopaedics">Pediatric Orthopaedics</option>
          <option value="Pediatrric Gastroenterology & Hepathology">Pediatrric Gastroenterology & Hepathology</option>
          <option value="Pediatric Intensive Care">Pediatric Intensive Care</option>
          <option value="Pediatric Oncology">Pediatric Oncology</option>
          <option value="Pediatric Endocrinology">Pediatric Endocrinology</option>
          <option value="Pediatric Cardiac Intensivist">Pediatric Cardiac Intensivist</option>
          <option value="Psychology">Psychology</option>
          <option value="Psychiatry">Psychiatry</option>
          <option value="Radiology/Radiodiagnosis">Radiology / Radiodiagnosis</option>
          <option value="Respiratory, Critical Care & Sleep Medicine">Respiratory, Critical Care & Sleep Medicine</option>
          <option value="Rheumatology">Rheumatology</option>
          <option value="Transplant Surgery">Transplant Surgery</option>
          <option value="Transplant and Surgical Gastroenterology">Transplant and Surgical Gastroenterology</option>
          <option value="Transfusion Medicine">Transfusion Medicine</option>
          <option value="Ultrasonography">Ultrasonography</option>
          <option value="Urology & Andrology">Urology & Andrology</option>
          <option value="Vascular & Endovascular Surgery">Vascular & Endovascular Surgery</option>










          {/* Add other specializations */}
        </select>
      </div>
      <div className="doctor-container">
        {filteredDoctors.map((doctor, index) => (
          <div className="doctor-card" key={index}>
            <img src="https://via.placeholder.com/150" alt="doctor Member" />
            <h3>{doctor.name}</h3>
            <p>{doctor.specialization}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DoctorCard;
