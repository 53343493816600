import React from 'react'
import DoctorCard from './DoctorCard'
import CallSection from '../../Elements/Call/CallSection'

const Doctors = () => {
  return (
    <>
      <DoctorCard/>
      <CallSection/>  
    </>
  )
}

export default Doctors
