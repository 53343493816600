import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../images/logo.svg';
import TopDesc from './TopDesc';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHospitalsOpen, setIsHospitalsOpen] = useState(false);
  const [isSpecialitiesOpen, setIsSpecialitiesOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleHospitals = () => {
    setIsHospitalsOpen(!isHospitalsOpen);
  };

  const toggleSpecialities = () => {
    setIsSpecialitiesOpen(!isSpecialitiesOpen);
  };

  return (
    <>
      <TopDesc />
      <nav className="navbar">
        <div className="navbar__logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="navbar__menu">
          <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <ul className={`navbar__links ${isMenuOpen ? 'open' : ''}`}>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li>
              <div className="dropdown">
                <button className="dropbtn" onClick={toggleHospitals}>
                  Hospitals <i className={`fa ${isHospitalsOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </button>
                {isHospitalsOpen && (
                  <div className="dropdown-content open">
                    <a href="https://www.apollohospitals.com/ahmedabad/" onClick={closeMenu}>Hospitals in Ahmedabad</a>
                    <a href="https://www.apollohospitals.com/bilaspur/" onClick={closeMenu}>Hospitals in Bilaspur</a>
                    <a href="https://www.apollohospitals.com/guwahati/" onClick={closeMenu}>Hospitals in Guwahati</a>
                    <a href="https://www.apollohospitals.com/kakinada/" onClick={closeMenu}>Hospitals in Kakinada</a>
                    <a href="https://www.apollohospitals.com/mumbai/" onClick={closeMenu}>Hospitals in Mumbai</a>
                    <a href="https://www.apollohospitals.com/nellore/" onClick={closeMenu}>Hospitals in Nellore</a>
                    <a href="https://www.apollohospitals.com/vizag/" onClick={closeMenu}>Hospitals in Visakhapatnam</a>
                    <a href="https://www.apollohospitals.com/aragonda/" onClick={closeMenu}>Hospitals in Aragonda</a>
                    <a href="https://www.apollohospitals.com/bhopal/" onClick={closeMenu}>Hospitals in Bhopal</a>
                    <a href="https://www.apollohospitals.com/hyderabad/" onClick={closeMenu}>Hospitals in Hyderabad</a>
                    <a href="https://www.apollohospitals.com/kaur/" onClick={closeMenu}>Hospitals in Kaur</a>
                    <a href="https://www.apollohospitals.com/madurai/" onClick={closeMenu}>Hospitals in Madurai</a>
                    <a href="https://www.apollohospitals.com/noida/" onClick={closeMenu}>Hospitals in Noida</a>
                    <a href="https://www.apollohospitals.com/bangalore/" onClick={closeMenu}>Hospitals in Bangalore</a>
                    <a href="https://www.apollohospitals.com/chennai/" onClick={closeMenu}>Hospitals in Chennai</a>
                    <a href="https://www.apollohospitals.com/indore/" onClick={closeMenu}>Hospitals in Indore</a>
                    <a href="https://www.apollohospitals.com/kochi/" onClick={closeMenu}>Hospitals in Kochi</a>
                    <a href="https://www.apollohospitals.com/mysore/" onClick={closeMenu}>Hospitals in Mysore</a>
                    <a href="https://www.apollohospitals.com/rourkela/" onClick={closeMenu}>Hospitals in Rourkela</a>
                    <a href="https://www.apollohospitals.com/bhubaneswar/" onClick={closeMenu}>Hospitals in Bhubaneswar</a>
                    <a href="https://www.apollohospitals.com/delhi/" onClick={closeMenu}>Hospitals in Delhi</a>
                    <a href="https://www.apollohospitals.com/kolkata/" onClick={closeMenu}>Hospitals in Kolkata</a>
                    <a href="https://www.apollohospitals.com/lucknow/" onClick={closeMenu}>Hospitals in Lucknow</a>
                    <a href="https://www.apollohospitals.com/nashik/" onClick={closeMenu}>Hospitals in Nashik</a>
                    <a href="https://www.apollohospitals.com/trichy/" onClick={closeMenu}>Hospitals in Trichy</a>
                  </div>
                )}
              </div>
            </li>
            <li>
              <div className="dropdown">
                <button className="dropbtn" onClick={toggleSpecialities}>
                  Specialities <i className={`fa ${isSpecialitiesOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </button>
                {isSpecialitiesOpen && (
                  <div className="dropdown-content open">
                    <a href="https://www.apollohospitals.com/departments/heart/" onClick={closeMenu}>Cardiology</a>
                    <a href="https://www.apollohospitals.com/departments/orthopedic/" onClick={closeMenu}>Orthopaedics</a>
                    <a href="https://www.apollohospitals.com/departments/spine/" onClick={closeMenu}>Spine Surgery</a>
                    <a href="https://www.apollohospitals.com/departments/neurology/" onClick={closeMenu}>Neurology</a>
                    <a href="https://www.apollohospitals.com/departments/gastroenterology/" onClick={closeMenu}>Gastroenterology</a>
                    <a href="https://www.apollohospitals.com/departments/urology/" onClick={closeMenu}>Urology</a>
                    <a href="https://www.apollohospitals.com/departments/nephrology/" onClick={closeMenu}>Nephrology</a>
                    <a href="https://www.apollohospitals.com/departments/oncology/" onClick={closeMenu}>Oncology</a>
                    <a href="https://www.apollohospitals.com/departments/gynecology/" onClick={closeMenu}>Gynecology</a>
                    <a href="https://www.apollohospitals.com/departments/pediatrics/" onClick={closeMenu}>Pediatrics</a>
                    <a href="https://www.apollohospitals.com/departments/ent/" onClick={closeMenu}>ENT</a>
                    <a href="https://www.apollohospitals.com/departments/dermatology/" onClick={closeMenu}>Dermatology</a>
                    <a href="https://www.apollohospitals.com/departments/psychiatry/" onClick={closeMenu}>Psychiatry</a>
                    <a href="https://www.apollohospitals.com/departments/dentistry/" onClick={closeMenu}>Dentistry</a>
                  </div>
                )}
              </div>
            </li>
            <li><Link to="/doctors" onClick={closeMenu}>Doctors</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Get Free Estimates</Link></li>

            <li><Link to="/appointment" className="appointment-button" onClick={closeMenu}>Book Appointment</Link></li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
