import React from 'react';
import { FaCalendarCheck, FaUserMd, FaPills, FaStethoscope, FaHospital, FaFileMedical } from 'react-icons/fa'; // Import Font Awesome icons
import './IconCard.css';

const IconCard = () => {
  return (
    <div className="icon-container">
      <div className="icon-title">What Would You Like To Do Today?</div>
      <div className="icon-card">
        <div className="icon-con">
          <FaCalendarCheck className='icons' />
          <div className='text-title'>Book Appointment</div>
        </div>
        <div className="icon-con">
          <FaUserMd  className='icons'/>
          <div className='text-title'>Consult Online</div>
        </div>
        <div className="icon-con">
          <FaPills className='icons' />
          <div className='text-title'>Buy Medicine</div>
        </div>
        <div className="icon-con">
          <FaStethoscope className='icons' />
          <div className='text-title'>Book Health Checkup</div>
        </div>
        <div className="icon-con">
          <FaHospital className='icons' />
          <div className='text-title'>Find Hospital</div>
        </div>
        <div className="icon-con">
          <FaFileMedical className='icons' />
          <div className='text-title'>View Health Records</div>
        </div>
      </div>
    </div>
  );
};

export default IconCard;
