import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faUserMd, faGlobe, faLightbulb, faCompass, faHandsHelping, faMapMarkedAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import './ServiceContent.css';
import { Parallax } from 'react-parallax';

function ServiceContent() {
  return (
    <Parallax strength={400} >
      <div className="servicecontent-container py-5">
        <h2 className="text-center mb-4 objectives-heading">View Your Journey on Apollo Hospital</h2>
        <div className="objective-card-carousel">
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faHeart} className="objectives-icon" />
                Scheduling Appointments
              </h3>
              <p className="objective-card-text">Avail complimentary consultation over the phone and guaranteed appointment with the doctor before your visit.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faUserMd} className="objectives-icon" />
                Visa & Travel Arrangement
              </h3>
              <p className="objective-card-text">Assured guidance in arranging Visa extension and invitation letter. Complimentary pick & drop facility from airport/hotel to the hospital</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faLightbulb} className="objectives-icon" />
                Single Point of Contact
              </h3>
              <p className="objective-card-text">Dedicated assistant for patients support before, during and post-treatment</p>
            </div>
          </div>
          {/* Add 5 more cards */}
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faGlobe} className="objectives-icon" />
                Global Access to Healthcare
              </h3>
              <p className="objective-card-text">Providing access to healthcare services globally, ensuring everyone has access to quality healthcare regardless of geographical location.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faCompass} className="objectives-icon" />
                Interpreter Facility
              </h3>
              <p className="objective-card-text">Free provision of interpreters familiar with medical terminologies and patients needs.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faHandsHelping} className="objectives-icon" />
                Stay & Food Arrangement
              </h3>
              <p className="objective-card-text">Assistance in arranging accommodation for patients attendants near the hospital. Sumptuous spread of local cuisines with a religious touch.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="objectives-icon" />
                Assured follow up
              </h3>
              <p className="objective-card-text">Avail complimentary consultation over the phone and guaranteed appointment with the doctor before your visit.</p>
            </div>
          </div>
          <div className="objective-card">
            <div className="objective-card-body">
              <h3 className="objective-card-title">
                <FontAwesomeIcon icon={faStar} className="objectives-icon" />
                Excellence in Patient Care
              </h3>
              <p className="objective-card-text">Striving for excellence in patient care, ensuring the highest standards of quality and safety in every aspect of healthcare delivery.</p>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default ServiceContent;
