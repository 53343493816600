import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Appointment.css';

const hospitals = [
  "Apollo Hospital, Ahmedabad",
  "Apollo Hospital, Bangalore",
  "Apollo Hospital, Chennai",
  "Apollo Hospital, Delhi",
  "Apollo Hospital, Hyderabad",
  "Apollo Hospital, Kolkata",
  "Apollo Hospital, Mumbai",
  "Apollo Hospital, Pune",
  "Apollo Hospital, Visakhapatnam",
  "Apollo Hospital, Madurai",
  "Apollo Hospital, Mysore",
  "Apollo Hospital, Nashik",
  "Apollo Hospital, Noida",
  "Apollo Hospital, Trichy",
  "Apollo Hospital, Bhubaneswar",
  "Apollo Hospital, Indore",
  "Apollo Hospital, Bhopal",
  "Apollo Hospital, Guwahati",
  "Apollo Hospital, Kochi",
  "Apollo Hospital, Rourkela",
  "Apollo Hospital, Kakinada",
  "Apollo Hospital, Nellore",
  "Apollo Hospital, Bilaspur",
  "Apollo Hospital, Aragonda",
  "Apollo Hospital, Lucknow",
];

const Appointment = () => {
  const [formData, setFormData] = useState({
    hospital: '',
    date: '',
    time: '',
    name: '',
    email: '',
    mobile: '',
  });

  const [validationMessages, setValidationMessages] = useState({
    hospital: '',
    date: '',
    time: '',
    name: '',
    email: '',
    mobile: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationMessages({ ...validationMessages, [name]: '' });
    setStatusMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newValidationMessages = { ...validationMessages };

    // Validate form fields
    if (!formData.hospital) {
      newValidationMessages.hospital = 'Please select a hospital';
      valid = false;
    }
    if (!formData.date) {
      newValidationMessages.date = 'Please select a date';
      valid = false;
    }
    if (!formData.time) {
      newValidationMessages.time = 'Please select a time';
      valid = false;
    }
    if (!formData.name.trim()) {
      newValidationMessages.name = 'Please enter your name';
      valid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.match(emailRegex)) {
      newValidationMessages.email = 'Please enter a valid email address';
      valid = false;
    }
    const mobileRegex = /^[0-9]{10}$/;
    if (!formData.mobile.match(mobileRegex)) {
      newValidationMessages.mobile = 'Please enter a valid 10-digit mobile number';
      valid = false;
    }

    setValidationMessages(newValidationMessages);

    if (valid) {
      emailjs.send('service_b6kt8ut', 'template_of8rgpe', formData, 'QD1QN8ZMmN17l-1xo')
        .then((result) => {
          console.log('Email successfully sent!', result.status, result.text);
          setStatusMessage('Appointment booked successfully!');
          setFormData({
            hospital: '',
            date: '',
            time: '',
            name: '',
            email: '',
            mobile: '',
          });
        })
        .catch((error) => {
          console.error('There was an error sending the email:', error);
          setStatusMessage('Failed to book appointment. Please try again.');
        });
    }
  };

  return (
    <div className="appointment-section">
      <h2>Book an Appointment</h2>
      <form className="appointment-form" onSubmit={handleSubmit}>
        <label htmlFor="hospital"></label>
        <select id="hospital" name="hospital" value={formData.hospital} onChange={handleChange}>
          <option value="">Choose a hospital</option>
          {hospitals.map((hospital, index) => (
            <option key={index} value={hospital}>{hospital}</option>
          ))}
        </select>
        {validationMessages.hospital && <p className="validation-message">{validationMessages.hospital}</p>}

        <label htmlFor="date"></label>
        <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} />
        {validationMessages.date && <p className="validation-message">{validationMessages.date}</p>}

        <label htmlFor="time"></label>
        <input type="time" id="time" name="time" value={formData.time} onChange={handleChange} />
        {validationMessages.time && <p className="validation-message">{validationMessages.time}</p>}

        <label htmlFor="name"></label>
        <input type="text" id="name" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} />
        {validationMessages.name && <p className="validation-message">{validationMessages.name}</p>}

        <label htmlFor="email"></label>
        <input type="email" id="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} />
        {validationMessages.email && <p className="validation-message">{validationMessages.email}</p>}

        <label htmlFor="mobile"></label>
        <input type="tel" id="mobile" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} />
        {validationMessages.mobile && <p className="validation-message">{validationMessages.mobile}</p>}

        <button type="submit">Book Appointment</button>
      </form>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
    </div>
  );
};

export default Appointment;
