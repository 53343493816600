import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const [validationMessages, setValidationMessages] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationMessages({ ...validationMessages, [name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newValidationMessages = { ...validationMessages };

    // Validate form fields
    if (!formData.name.trim()) {
      newValidationMessages.name = 'Please enter your name';
      valid = false;
    }

    const mobileRegex = /^[0-9]{10}$/;
    if (!formData.mobile.match(mobileRegex)) {
      newValidationMessages.mobile = 'Please enter a valid 10-digit mobile number';
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.match(emailRegex)) {
      newValidationMessages.email = 'Please enter a valid email address';
      valid = false;
    }

    if (!formData.message.trim()) {
      newValidationMessages.message = 'Please enter a message';
      valid = false;
    }

    setValidationMessages(newValidationMessages);

    if (valid) {
      const serviceID = 'service_b6kt8ut';
      const templateID = 'template_4q85beg';
      const userID = 'QD1QN8ZMmN17l-1xo';

      emailjs.send(serviceID, templateID, formData, userID)
        .then((response) => {
          console.log('Email sent successfully!', response.status, response.text);
          setFormData({ name: '', mobile: '', email: '', message: '' });
        })
        .catch((err) => {
          console.error('Failed to send email:', err);
        });
    }
  };

  return (
    <div className="contact-section">
      <div className="contact-description">
        <h2>Contact Us</h2>
        <p>If you have any questions or need assistance, feel free to reach out to us:</p>
        <ul>
          <li><i className="fas fa-envelope"></i> Email: aicnepal10@gmail.com</li>
          <li><i className="fas fa-phone"></i> Phone: 9869182611</li>
          <li><i className="fas fa-map-marker-alt"></i> Address: Naxal, Bhatbhateni, Kathmandu</li>
        </ul>
      </div>
      <div className="contact-form">
        <h1>Fill in the Form</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name"></label>
          <input type="text" id="name" name="name" placeholder='Enter a Full Name' value={formData.name} onChange={handleChange} />
          {validationMessages.name && <p className="validation-message">{validationMessages.name}</p>}

          <label htmlFor="mobile"></label>
          <input type="tel" id="mobile" name="mobile" placeholder='Enter a Mobile Number' value={formData.mobile} onChange={handleChange} />
          {validationMessages.mobile && <p className="validation-message">{validationMessages.mobile}</p>}

          <label htmlFor="email"></label>
          <input type="email" id="email" name="email" placeholder='Enter an Email Address' value={formData.email} onChange={handleChange} />
          {validationMessages.email && <p className="validation-message">{validationMessages.email}</p>}

          <label htmlFor="message"></label>
          <textarea id="message" name="message" placeholder='Enter a Message' value={formData.message} onChange={handleChange}></textarea>
          {validationMessages.message && <p className="validation-message">{validationMessages.message}</p>}

          <button className='button' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
