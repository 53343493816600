import React from 'react';
import './ContactImage.css';
import contacticon from '../../images/cont.png';

const ContactImage = () => {
  return (
    <div className="image-section-con">
      <div className="left-content">
        <h2>Our Contact</h2>
      </div>
      <div className="right-content">
        <img src={contacticon} alt="Contact Icon" />
      </div>
    </div>
  );
};

export default ContactImage;
