import React from 'react'
import './Blog.css'
import blog1 from '../../images/apollo-news.jpg'
import blog2 from '../../images/apollo-health-of-the-nation.jpg'
import blog3 from '../../images/IBA_thumb.jpg'
import blog4 from '../../images/4th-proton-practicum.jpg'
const Blog = () => {
  return (
    <>
    <section className="blog-section">
        <h1>What's new at Apollo</h1>
        <div className="blog-container">
            <div className="blog-card">
            <img src={blog1} alt="Blog Image"/>
            <div className="blog-info">
                <span className="blog-date">April 25, 2024</span>
                <h2 className="blog-title">Apollo Hospitals Group becomes the largest hospital network to have the most JCI Accredita...</h2>
                <p className="blog-summary">National, 25th April, 2024: Apollo Hospitals Group announced its seventh consecu...</p>
                <a href="https://www.apollohospitals.com/apollo-in-the-news/apollo-hospitals-group-becomes-the-largest-hospital-network-to-have-the-most-jci-accreditations/" className="read-more">Read More</a>
            </div>
            </div>
            <div className="blog-card">
            <img src={blog2} alt="Blog Image"/>
            <div className="blog-info">
                <span className="blog-date">April 05, 2024</span>
                <h2 className="blog-title">On World Health Day, Apollo Hospitals has unveiled the 4th edition of the Health of Nation...</h2>
                <p className="blog-summary">Apollo Hospitals has unveiled the latest edition of its flagship annual report, ...</p>
                <a href="https://www.apollohospitals.com/apollo-in-the-news/on-world-health-day-apollo-hospitals-has-unveiled-the-4th-edition-of-the-health-of-nation-report/" className="read-more">Read More</a>
            </div>
            </div>
            <div className="blog-card">
            <img src={blog3} alt="Blog Image"/>
            <div className="blog-info">
                <span className="blog-date">March 28, 2024</span>
                <h2 className="blog-title">Apollo Proton Cancer Centre in collaboration With Belgium-Based IBA Trains International D...</h2>
                <p className="blog-summary">Apollo Proton Cancer Centre (APCC) is poised to revolutionize global healthcare...</p>
                <a href="https://www.apollohospitals.com/apollo-news/apollo-proton-cancer-centre-in-collaboration-with-belgium-based-iba-trains-international-doctors-for-proton-beam-therapy/" className="read-more">Read More</a>
            </div>
            </div>
            <div className="blog-card">
            <img src={blog4} alt="Blog Image"/>
            <div className="blog-info">
                <span className="blog-date">10th-12th May, 2024</span>
                <h2 className="blog-title">4th Apollo Annual Proton Practicum</h2>
                <p className="blog-summary">Improving Survival and Survivorship in Paediatric and CNS Tumours with Proton...</p>
                <a href="https://www.apollohospitals.com/events/4th-apollo-annual-proton-practicum/" className="read-more">Read More</a>
            </div>
            </div>
        </div>
        </section>

      
    </>
  )
}

export default Blog
