import React, { useState, useEffect } from 'react';
import './Service.css'; // Import CSS file for styling
import { Parallax } from 'react-parallax';

const Service = () => {
  const [counts, setCounts] = useState({
    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0,
    count5: 0,
    count6: 0
   
  });

  const targets = [73,4500,16,10000,11000,200];
  const duration = 3000; // Duration of animation in milliseconds

  useEffect(() => {
    const timers = targets.map((target, index) => {
      const increment = Math.ceil(target / (duration / 1000)); // Calculate increment per second
      return setInterval(() => {
        setCounts(prevCounts => ({
          ...prevCounts,
          [`count${index + 1}`]: Math.min(prevCounts[`count${index + 1}`] + increment, target)
        }));
      }, 1000); // Update every second
    });

    return () => {
      timers.forEach(timer => clearInterval(timer));
    };
  }, [targets]);

  return (
    <Parallax strength={400} className='parallax' >
    <div className='counting-container'>
      <h1>Typical Day at Apollo Hospitals</h1>
      <div className="count-cards">
        {targets.map((target, index) => (
          <div key={index} className="count-card">
            <div className="icon-circle">
              {index === 0 && <i className="fas fa-hospital fa-3x"></i>}
              {index === 1 && <i className="fas fa-stethoscope fa-3x"></i>}
              {index === 2 && <i className="fas fa-hands-helping fa-3x"></i>}
              {index === 3 && <i className="fas fa-bed fa-3x"></i>}
              {index === 4 && <i className="fas fa-user-md fa-3x"></i>}
              {index === 5 && <i className="fas fa-medkit fa-3x"></i>}
            </div>
            <span>{counts[`count${index + 1}`]}</span>
            <p>
              {target === 73 && "Hospitals"}
              {target === 4500 && "Pharmacies"}
              {target === 16 && "Birthing Centers"}
              {target === 10000 && "Beds"}
              {target === 11000 && "Doctors"} 
              {target === 200 && "Telemedicine Centres"}

            </p>
          </div>
        ))}
      </div>
    </div>
    </Parallax>
  );
};

export default Service;
