import React from 'react';
import './HeroSection.css'; // You can create a separate CSS file for styling
import hero from '../../images/hero.jpg';

const HeroSection = () => {
  return (
    <div className="heroim-section">
      <div className="hero-section__content">
        <div className="hero-section__text">
          <h1>Why Choose Apollo Healthcare?</h1>
          <p>Established by Dr Prathap C Reddy in 1983, Apollo Healthcare has a robust presence across the healthcare ecosystem. From routine wellness & preventive health care to innovative life-saving treatments and diagnostic services, Apollo Hospitals has touched more than 200 million lives from over 120 countries.</p>
          <p>Our pioneering efforts have resulted in Apollo Hospitals becoming Asia's largest and most trusted healthcare group. 
              We have consistently been a trailblazer in delivering state-of-the-art healthcare to Southeast Asia as also the MENA region.
          </p>
          <p>Over the decades, we have touched millions of lives by providing care for the simplest conditions to the most compicated ones.</p>
        </div>
        <div className="hero-section__image">
          <img src={hero} alt="Hero Image" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
